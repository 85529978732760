import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_URL_TOKEN } from 'src/app/shared/tokens';
import { AuthConfig } from '../configs/auth.config';
import { AUTH_CONFIG_TOKEN } from '../tokens';


@Injectable({
    providedIn: 'root'
})
export class ApplicationIdInterceptor implements HttpInterceptor {
    constructor(
        @Inject(API_URL_TOKEN) private apiUrl: string,
        @Inject(AUTH_CONFIG_TOKEN) private authConfig: AuthConfig
    ) { }

    public intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        if (!(req.url.startsWith(this.apiUrl) || req.url.startsWith(this.authConfig.apiUrl))) return next.handle(req);

        const newReq = req.clone({
            headers: req.headers.set('application', 'event_tube'),
        });

        return next.handle(newReq);
    }
}
