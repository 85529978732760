import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router } from '@angular/router';
import { map, Observable, tap } from 'rxjs';
import { AlertsService } from 'src/app/alerts/services/alerts.service';
import { AlertType } from 'src/app/alerts/types/alert.type';
import { UserService } from 'src/app/user/services/user.service';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
    constructor(
        private userService: UserService,
        private alertsService: AlertsService,
        private router: Router
    ) { }

    public canActivate(): Observable<boolean> {
        return this.userService.currentUser.pipe(
            map((currentUser) => {
                return currentUser?.adminRole !== undefined;
            }),
            tap((hasAccess) => {
                if (!hasAccess) {
                    this.alertsService.createAlert({
                        alertType: AlertType.Error,
                        alertMessage: 'You are not an authorized admin for this platform.'
                    });

                    this.router.navigate(['/unauthorized']);
                }
            })
        )
    }

    public canActivateChild(): Observable<boolean> {
        return this.canActivate();
    }
}
